import {
    gsap,
    Power2,
    Power3,
    Bounce
} from "gsap/all"

export default class SVGLink {
    constructor(_link) {
        this.link = _link
        this.svg = _link.querySelector("svg")
        this.children = this.svg.children
        this.setTL()
        this.events()
    }

    events() {
        this.link.addEventListener("mouseenter", (e) => this.onMouseEnter())
        this.link.addEventListener("mouseleave", (e) => this.onMouseLeave())
    }

    setTL() {
        this.tl = gsap.timeline({
            paused: true
        })
        for (let i = 0; i < this.children.length; i++) {
            let children = this.children[i]
            let delay = Math.random()
            this.tl.to(children, {
                duration: Math.random() + .15,
                ease: Power2.easeOut,
                strokeOpacity: 1
            }, delay).to(children, {
                duration: Math.random() * 2 + 1,
                ease: Bounce.easeOut,
                fill: "transparent",
            }, delay).to(children, {
                duration: Math.random() + .5,
                ease: Bounce.easeIn,
                stroke: "#FFF"
                // stroke: "#00ffd2"
            }, delay + .15).to(children, {
                duration: 3,
                ease: Power3.easeInOut,
                strokeDasharray: 50,
                strokeDashoffset: 0
            }, delay).set(children, {
                strokeDasharray: 100,
                strokeDashoffset: 100,
                stroke: "#000"
                // stroke: "#ff007a"
            }, delay + .5).set(children, {
                strokeDasharray: 0,
                strokeDashoffset: 0
            }, delay + 3)
        }
    }

    onMouseEnter() {
        return this.tl.duration(1).play()
    }

    onMouseLeave() {
        return this.tl.duration(.35).reverse()
    }

}