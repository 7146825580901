import {
    gsap,
    Power2,
    Circ,
    Bounce
} from "gsap/all"

import SVGLink from "./link_svg.js"
import MenuBtn from "./menu_btn.js"

Math.inRange = (v, low, high) => {
    return Math.max(Math.min(v, high), low);
}

Math.map = (x, a, b, c, d, bounds) => {
    var value = ((x - a) * (d - c)) / (b - a) + c;
    if (!bounds) return value
    if (c < d) return Math.inRange(value, c, d)
    else return Math.inRange(value, d, c)
}

Math.lerp = (a, b, n) => {
    return (1 - n) * a + n * b
}

export default class Menu {
    constructor() {
        this.state = "close"
        this.container = document.querySelector("#main-menu")
        this.toHomeLink = document.querySelector("a#to-home")
        //
        this.openBtn = new MenuBtn(document.querySelector("#menu-open-btn"))
        this.closeBtn = new MenuBtn(document.querySelector("#menu-close-btn"))
        //
        this.links = this.container.querySelectorAll("a.menu-link")
        this.footer = this.container.querySelectorAll("footer")

        this.links.forEach(el => new SVGLink(el))
        window.LOAD_EVENT.on('loaded', () => this.start())
    }

    start() {
        gsap.delayedCall(.5, () => this.openBtn.show())
        gsap.to("#to-home", {
            ease: Power2.easeOut,
            duration: .65,
            delay: 1,
            opacity: 1,
            display: "block"
        })
        this.setTL()
        this.events()
    }

    events() {
        // window.addEventListener("keypress", (e) => {
            // let _key = String(e.key).toLowerCase()
            // if (this.playerContainer.classList.contains("open") && _key == " ") return e.preventDefault()
        // })
   
        window.addEventListener("keyup", (e) => {
            let _key = String(e.key).toLowerCase()
            switch (_key) {
                case "escape":
                    return this.close()
            }
        })

        this.openBtn.btn.addEventListener("click", () => this.open())
        this.closeBtn.btn.addEventListener("click", () => this.close())
        this.toHomeLink.addEventListener("click", () => this.close())
        //
        this.links.forEach(_link => _link.addEventListener("click", () => this.close()))
        //
    }

    open() {
        if (this.state == "open") return false
        this.openBtn.hide(.45)
        gsap.delayedCall(.65, () => this.closeBtn.show(1.65))
        this.tl.duration(1.65).play()
        document.querySelectorAll("video").forEach(el => el.pause())
        this.state = "open"
    }

    close() {
        if (this.state == "close") return false
        this.closeBtn.hide(.45)
        gsap.delayedCall(.8, () => this.openBtn.show(1.65))
        this.tl.duration(1.35).reverse()
        this.state = "close"
    }

    setTL() {
        this.tl = new gsap.timeline({
            paused: true
        })
        this.tl.to(this.container, {
            ease: Power2.easeOut,
            display: "block",
            duration: 0.35,
            opacity: 1
        }, 0).fromTo(this.footer, {
            y: '100%',
            scaleX: .75
        }, {
            y: '0%',
            scaleX: 1,
            duration: .85,
            ease: 'power2.out'
        }, .9).to(this.footer, {
            duration: .9,
            ease: Bounce.easeOut,
            opacity: 1
        }, 1.05)
        for (let i = 0; i < this.links.length; i++) {
            this.tl.fromTo(this.links[i], {
                y: '100%',
                scaleX: .75,
                opacity: 0
            }, {
                y: '0%',
                scaleX: 1,
                opacity: 1,
                duration: .85,
                ease: 'power2.out'
            }, i * .075 + .15)
        }
    }

}