import {
    gsap,
    Power2,
    Circ,
    Bounce
} from "gsap/all"
export default class MenuBtn {
    constructor(_btn) {
        this.btn = _btn
        this.status = "close"
        this.inOver = false
        //
        this.children = this.btn.querySelector("svg").children
        // this.events()
        this.setTL()
    }

    events() {
        this.btn.addEventListener("mouseenter", () => this.onMouseEnter(), false)
        this.btn.addEventListener("mouseleave", () => this.onMouseLeave(), false)
    }

    setTL() {
        this.tl = gsap.timeline({
            paused: true
        })
        gsap.set(this.children, {
            strokeDasharray: 8,
            strokeDashoffset: 8,
            stroke: "transparent"
        })
        this.tl.to(this.btn, {
            duration: .15,
            ease: Circ.easeOut,
            opacity: 1,
            display: "block"
        }, 0)
        this.tl.to(this.children, {
            duration: .35,
            ease: Circ.easeOut,
            stagger: 0.015,
            strokeDasharray: 44,
            strokeDashoffset: 22
        }, .1).to(this.children, {
            duration: .15,
            ease: Bounce.easeOut,
            stagger: 0.015,
            stroke: window.BACKGROUND
        }, 0).to(this.children, {
            duration: .65,
            ease: Bounce.easeOut,
            stagger: 0.015,
            stroke: window.COLOR
        }, .1)
    }

    show(_duration = 1) {
        if (this.status == "show") return false
        this.status = "show"
        return this.tl.duration(_duration).play()
    }

    hide(_duration = .65) {
        if (this.status == "close") return false
        this.status = "close"
        return this.tl.duration(_duration).reverse()
    }

    onMouseEnter() {
        console.log("onMouseEnter")
        if (this.inOver) return false
        gsap.to(this.btn, {
            duration: .15,
            ease: Circ.easeOut,
            scaleX: .95,
            scaleY: .95
        })
        this.inOver = true
    }

    onMouseLeave() {
        console.log("onMouseLeave")
        if (!this.inOver) return false
        gsap.to(this.btn, {
            duration: .15,
            ease: Circ.easeOut,
            scaleX: 1,
            scaleY: 1
        })
        this.inOver = false
    }
}