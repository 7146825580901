const PX_RATIO = Math.min(window.devicePixelRatio, 2)

Math.map = (n, start, stop, start2, stop2) => {
    const newval = (n - start) / (stop - start) * (stop2 - start2) + start2
    return newval
}

Math.dist = (a, b) => {
    let dx = a.x - b.x,
        dy = a.y - b.y;
    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}
export default class Text {
    constructor(txt, args) {
        if (args === undefined) args = {}
        this.canvas = document.createElement("canvas")
        document.body.appendChild(this.canvas)
        this.canvas.style.visibility = "hidden"
        this.context = this.canvas.getContext("2d")
        this.context.webkitImageSmoothingEnabled = false;
        this.context.mozImageSmoothingEnabled = false;
        this.context.msImageSmoothingEnabled = false;
        this.context.imageSmoothingEnabled = false;
        // 
        this.center = {
            x: 0,
            y: 0
        }
        this.pos = {
            x: 0,
            y: 0
        }
        this.mouse = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        }
        // 
        this.fontSize = 100
        this.lineHeight = this.fontSize * 0.9
        this.font = this.fontSize + "px Oswald"
        this.fillStyle = args.color || "#fff"
        this.txt = txt.toUpperCase() || "Oswald"
        // 
        this.friction = 0.1
        this.addEvents()
        // 
        this.resize()
    }

    get texture() {
        return this.canvas
    }

    resize() {
        this.height = this.fontSize
        this.canvas.width = 650
        this.canvas.height = this.height
        this.center = {
            x: (window.innerWidth / 2) * PX_RATIO,
            y: (window.innerHeight / 2) * PX_RATIO
        }
        this.diagonal = Math.dist(this.center, {
            x: window.innerWidth * PX_RATIO,
            y: window.innerHeight * PX_RATIO
        })
    }

    addEvents() {
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
        document.addEventListener('touchmove', this.onMouseMove.bind(this), false)
    }

    onMouseMove(e) {
        if (e.touches) e = e.touches[0]
        this.mouse.x = e.clientX * PX_RATIO
        this.mouse.y = e.clientY * PX_RATIO
    }

    update() {
        this.pos.x += (this.mouse.x - this.pos.x) * this.friction
        this.pos.y += (this.mouse.y - this.pos.y) * this.friction
        let dist = Math.dist(this.pos, this.center)
        this.dist = dist / this.diagonal
        this.wght = Math.map(dist, 0, this.diagonal, 700, 100)
        console.log(this.dist)
    }

    render() {
        this.update()
        this.context.fillStyle = "#000"
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
        // 
        this.context.fillStyle = this.fillStyle
        this.context.font = this.font
        this.width = this.context.measureText(this.text).width
        this.context.fillText(this.txt, (420 - this.width) / 2, this.lineHeight)
        this.canvas.style.fontVariationSettings = "'wght' " + this.wght.toFixed(2)
    }
}